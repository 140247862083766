<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.adi }} {{ mainData.soyadi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="İLGİLİ KİŞİ" entityName="contact"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="adi">Adı <span style="color:red">*</span></label>
								<InputText id="adi" type="text" v-model="mainData.adi" />
								<span v-if="v$.mainData.adi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.adi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="soyadi">Soyadı <span style="color:red">*</span></label>
								<InputText id="soyadi" type="text" v-model="mainData.soyadi" />
								<span v-if="v$.mainData.soyadi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.soyadi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="unvani">İş Ünvanı</label>
								<InputText id="unvani" type="text" v-model="mainData.unvani" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bagliOlduguFirma" v-model="mainData.firmaName" ref="entity_firma" label="Bağlı Olduğu Firma" entityName="account" nameField="name" :state="true" :required="true"
								@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null" ></EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>İLETİŞİM BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="telefon1">Telefon</label>
								<InputMask id="telefon1" v-model="mainData.telefon1" mask="(999) 999-9999" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="dahilino">Dahili No</label>
								<InputText id="dahilino" type="text" v-model="mainData.dahilino" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ceptelefonu">Cep Telefonu</label>
								<InputMask id="ceptelefonu" v-model="mainData.cepTelefonu" mask="(999) 999-9999" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="eposta">E-posta Adresi</label>
								<InputText id="eposta" type="text" v-model="mainData.eposta" />
								<span v-if="v$.mainData.eposta.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.eposta.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="eposta">E-posta Adresi 2</label>
								<InputText id="eposta" type="text" v-model="mainData.eposta2" :disabled="isDisabled" />
								<span v-if="v$.mainData.eposta2.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.eposta2.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bultenGonderilecekmi">Bülten Gönderilecek mi?</label><br/>
								<InputSwitch id="bultenGonderilecekmi" v-model="mainData.bultenGonderilecekmi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisRaporuGonderilecekmi">Servis Raporu Gönderilecek mi?</label><br/>
								<InputSwitch id="servisRaporuGonderilecekmi" v-model="mainData.servisRaporuGonderilecekmi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="smsGonderilecekmi">SMS Gönderilecek mi?</label><br/>
								<InputSwitch id="smsGonderilecekmi" v-model="mainData.smsGonderilecekmi" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 2,
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				}
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

        debugger;
		if (this.entityId != null) {
			try {
				debugger;
				this.crmService.GetEntityById({
				"entityName": "account",
				"entityId": this.entityId,
				"columnNames": ["name", "accountid", "telephone1"]})
				.then(data_account => {
					if (data_account.jsonData != null) {
						debugger;

						this.mainData["telefon1"] = data_account.jsonData["telephone1"];

						this.mainData["firmaId"] = data_account.jsonData["accountid"];
						this.mainData["firmaName"] = data_account.jsonData["name"];

						let accountItem = { "Value": data_account.jsonData["accountid"], "Name": data_account.jsonData["name"] };

						this.$refs.entity_firma.setDefaultData(accountItem);
						this.firmaSelected = accountItem;
					}
				})
				.catch(error => console.log(error));

			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		}
		else {
			loader.hide();
		}
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		firmaSelected: {
			get: function () {
				debugger;
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;

			if (this.mainData.telefon1 != null && this.mainData.telefon1.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen telefon numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			if (this.mainData.cepTelefonu != null && this.mainData.cepTelefonu.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Cep Telefon numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getIlgiliKisiCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});

							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: 'contact', params: { id: response.olusturulanId } });
								}, 1000);
							}
							
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'İlgili Kişiler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				adi: {
					required : helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
				soyadi: {
					required : helpers.withMessage('Soyadı alanı dolu olmalıdır!', required),
				},
				eposta: {
					email : helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
				eposta2: {
					email : helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
				firmaName: {
					required : helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
